
/* eslint-disable @typescript-eslint/camelcase */
import {computed, defineComponent, onMounted, PropType, ref} from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import RatesDataTable from "@/components/jobs/Misc/WorkerRatesDataTable.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { fireSwal } from "@/components/jobs/Misc/helpers";

export default defineComponent({
  name: "worker-rates-tab",
  components: {
    LoadingSpinner,
    RatesDataTable
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const job = computed(
        () => store.getters['JobModule/item']
    );
    const key = ref(0);
    const isSavingRates = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    onMounted(() => {
        isLoading.value = true;
        store.dispatch("JobModule/fetchWorkerRates", job.value.id)
            .finally(() => {
              isLoading.value = false;
            });
    });

    const rerenderTableElement = () => {
      key.value++;
    }

    const handleSaveRates = (data) => {
      const payload = {
        rates: data,
        job_id: job.value.id
      }
      isSavingRates.value = true;
      store.dispatch("JobModule/saveWorkerRate", payload)
          .finally(() => {
            store.dispatch("JobModule/fetchWorkerRates", job.value.id)
                .finally(() => {
                  rerenderTableElement();
                  isSavingRates.value = false;
                  fireSwal();
                });
          })
    }

    return {
      rerenderTableElement,
      handleSaveRates,
      isSavingRates,
      key,
      isLoading
    }
  }
})
