
/* eslint-disable @typescript-eslint/camelcase */
import {computed, defineComponent, onMounted, ref, PropType, reactive, nextTick} from "vue";
import {useStore} from "vuex";
import CustomizeRatesModal from "@/components/jobs/Misc/CustomizeRatesModal.vue";
import { Modal } from "bootstrap";
import {fireSwal} from "@/components/jobs/Misc/helpers";

export default defineComponent({
  name: 'job-worker-rates-data-table',
  components: {
    CustomizeRatesModal
  },
  props: {
    isSavingRates: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props , {emit}) {
    const store = useStore();
    const rates = computed(() => store.getters['JobModule/getSkillRates']);
    const model = reactive({});
    const bulkEditList = ref<number[]>([]);
    const key = ref(0);
    const isSavingCustom = ref(false);
    const skillID = ref<number|null>(null);
    const modalKey = ref(0);
    const modal = ref<Modal|null>(null);
    const job = computed(
        () => store.getters['JobModule/item']
    );
    const hasModel = computed<boolean>(() => Object.keys(model).length > 0);
    const bulkEdit = reactive<any>({
      base_rate: "",
      weekend_bonus:"",
      night_bonus: "",
      level_bonus: ""
    });
    const buildModel = () => {
      for(let i = 0; i < rates.value.skills.length; i++) {
        const skill = rates.value.skills[i];
        const template = {
          base_rate: null,
          weekend_bonus: null,
          night_bonus: null,
          level_bonus: null,
          skill_id: skill.id,
        };
        const existingRate = rates.value.historicalRates.find(rate => rate.skill_id == skill.id && rate.level_id == null);
        if(existingRate) {
          template.base_rate = existingRate.base_rate;
          template.weekend_bonus = existingRate.weekend_bonus;
          template.night_bonus = existingRate.night_bonus;
          template.level_bonus = existingRate.level_bonus;
        }
        model[skill.id] = template;
      }
    };

    onMounted(() => {
      store.dispatch("LevelsModule/fetchData");
      buildModel();
    });

    const emitSaveRates = () => {
      emit('saveRates', model);
    };

    const handleBulkEdit = () => {
      bulkEditList.value.forEach(skill => {
        model[skill].base_rate =
            bulkEdit.base_rate != ""
                ? bulkEdit.base_rate
                : model[skill].base_rate;
        model[skill].night_bonus =
            bulkEdit.night_bonus != ""
                ? bulkEdit.night_bonus
                : model[skill].night_bonus;
        model[skill].weekend_bonus =
            bulkEdit.weekend_bonus != ""
                ? bulkEdit.weekend_bonus
                : model[skill].weekend_bonus;
        model[skill].level_bonus =
            bulkEdit.level_bonus != ""
                ? bulkEdit.level_bonus
                : model[skill].level_bonus;
      });
    };

    const handleBulkEditList = (skillId) => {
      if(bulkEditList.value.includes(skillId)) {
        bulkEditList.value.splice(bulkEdit.value.indexOf(skillId), 1);
      } else {
        bulkEditList.value.push(skillId);
      }
    };

    const handleSelectAllRows = (event) => {
      if(event) {
        bulkEditList.value = Object.keys(model).map(item => parseInt(item));
      } else {
        bulkEditList.value = [];
      }
      key.value++;
    };

    const handleCustomizeModal = async (id: number)  => {
      skillID.value = id;
      modalKey.value++;
      await nextTick().then(() => {
        modal.value = new Modal(document.getElementById("customize-rates-modal"));
        modal.value.show();
      });
    };

    const handleSaveRatesLevel = (model) => {
        isSavingCustom.value = true;
        const payload = {
          rates: model,
          job_id: job.value.id,
          is_level: true
        }
        store.dispatch("JobModule/saveWorkerRate", payload)
            .finally(() => {
              store.dispatch("JobModule/fetchWorkerRates", job.value.id)
                  .finally(() => {
                    isSavingCustom.value = false;
                    modal.value.hide();
                    skillID.value = null;
                    modalKey.value++;
                    emit('update-state');
                  })
            });
    };
    return {
      handleBulkEditList,
      emitSaveRates,
      handleBulkEdit,
      handleCustomizeModal,
      handleSelectAllRows,
      handleSaveRatesLevel,
      isSavingCustom,
      skillID,
      modalKey,
      bulkEditList,
      key,
      bulkEdit,
      rates,
      model,
      hasModel
    }
  }
})
