
/* eslint-disable @typescript-eslint/camelcase */
import {computed, defineComponent, onMounted, PropType, reactive, ref} from "vue";
import { useStore } from "vuex";
import _ from "lodash";

type Level = {
  id: number;
  name: string;
  weight: number;
  updated_at: string;
  created_at: string;
  deleted_at: string | null;
};

export default defineComponent({
  name: "customize-rates-modal",
  props: {
    skillId : {
      type: [Number, null] as PropType<number|null>,
      default: () => null
    },
    isSaving: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const rates = computed(() =>
      store.getters["JobModule/getSkillRates"]
    );
    const levels = computed(() => store.getters["LevelsModule/getData"]);
    const orderedLevels = computed<Level[]>(() => {
      return _.orderBy(levels.value, "weight", "desc");
    });
    const model = reactive({});
    const hasModelData = computed<boolean>(() => {
      return Object.keys(model).length > 0
    });
    const buildRatesModel = () => {
      if(props.skillId != null) {
        for(let i = 0; i < orderedLevels.value.length; i++) {
          const template = {
            base_rate: "",
            night_bonus: "",
            weekend_bonus: "",
            level_id: orderedLevels.value[i].id,
            skill_id: props.skillId
          };
          const existingRate = rates.value.historicalRates.find(item => item.skill_id == props.skillId && item.level_id == orderedLevels.value[i].id)
          if(existingRate) {
            template.base_rate = existingRate.base_rate;
            template.night_bonus = existingRate.night_bonus;
            template.weekend_bonus = existingRate.weekend_bonus;
          }
          model[orderedLevels.value[i].id] = template;
        }
      }
    }
    onMounted(() => {
      buildRatesModel();
    });

    const handleSaveLevelRates = () => {
      emit('save-level-rates', model);
    };

    return {
      handleSaveLevelRates,
      orderedLevels,
      hasModelData,
      model
    }
  }
})
